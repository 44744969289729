




























































import { Component, Prop, Vue } from 'vue-property-decorator';
// import { f7List, f7ListInput } from 'framework7-vue';
// Import Framework7 Styles
// import 'framework7/css/framework7.bundle.css';
import { login } from '@/scripts/api/login';
import { errHandle } from '@/scripts/utils/library';

export default Vue.extend({
    data() {
        return {
            formData: {
                mobile: '',
                password: '',
            },
            formRules: {
                mobile: [
                    { required: true, len: 8, pattern: /^[0-9]+$/, message: '請輸入8位數字香港手提電話號碼', trigger: ['blur'] },
                ],
                password: [
                    { required: true, message: '請輸入密碼', trigger: ['blur'] },
                ],
            },
            submitting: false,
        };
    },
    methods: {
        onSubmit() {
            (this.$refs.form as any).validate((valid: boolean, validError: any) => {
                if (valid) {
                    // valid ok
                    console.debug('valid ok');
                    this.submitting = true;
                    login(this.formData).then((response) => {
                        console.debug('submit success, show response');
                        console.dir(response);
                        const payload = {loginSess: response.data.login_session, mobile: this.formData.mobile};
                        this.$store.dispatch('logined', payload);
                        this.$router.push('/loan-app');
                    }).catch((error) => {
                        errHandle(this, error);
                        console.debug('submit error:' + error);
                    }).finally(() => {
                        console.debug('submit finally');
                        this.submitting = false;
                    });
                } else {
                    errHandle(this, validError);
                    console.debug('error submit!!');
                    return false;
                }
            });
        },
    },
});
